@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .landing-pading {
    @apply px-5 md:px-14 lg:px-20 xl:px-28 2xl:px-32;
  }
  .landing-pading-left {
    @apply pl-5 md:pl-14 lg:pl-20 xl:pl-28 2xl:pl-52;
  }
}

.scb-lines div:nth-child(1):after {
  content: '';
  background-color: #222222;
  display: block;
  width: 0.05rem;
  height: 4rem;
  animation: scroll 2.4s cubic-bezier(0.86, 0, 0.07, 1) infinite;
}

.scb-lines div:nth-child(2):after {
  content: '';
  background-color: #222222;
  display: block;
  width: 0.05rem;
  height: 2.5rem;
  animation: scroll 2.4s cubic-bezier(0.86, 0, 0.07, 1) infinite;
  animation-delay: 0.2s;
}

@keyframes scroll {
  0% {
    transform: translateY(-100%);
  }
  30% {
    transform: translateY(0%);
  }
  70% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes bars {
  0% {
    transform: translateX(-100%);
  }
  30% {
    transform: translateX(0%);
  }
  70% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
